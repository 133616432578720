<template>
  <div class="crown__flats">
    <div class="loader" v-if="startLoader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <div
        class="fixed-overlay-disclaimer fixed-overlay__modal-disclaimer disclaimer"
        v-if="disclaimer == true"
    >
      <div class="modal-disclaimer">
        <div class="modal_container-disclaimer">
          <h3>Hinweis</h3>
          <p>
            Bei der in diesem Konfigurator dargestellten Immobilie handelt es
            sich um ein in der Planung bzw. noch im Bau befindliches Objekt
          </p>
          <p>
            Alle Angaben, Illustrationen und Visualisierungen wurden mit größter
            Sorgfalt zusammengestellt, dennoch stellen sie einen unverbindlichen
            und vorläufigen Planungsstand dar.
          </p>
          <p>
            Die konkrete Lage, Ausrichtung und Ausstattung der Wohnungen ist
            ausschließlich der Vertragsdokumentation (Kaufvertrag nebst Anlagen)
            zu entnehmen.
          </p>
          <p>
            Die in den Plänen und in den Visualisierungen dargestellte
            Dekoration und Möblierung dient lediglich der Veranschaulichung und
            gehört nicht zum Kaufgegenstand.
          </p>
          <button v-on:click="sendAkzeptieren(true)">Akzeptieren</button>
          <button v-on:click="sendAkzeptieren(false)">Ablehnen</button>
        </div>
      </div>
    </div>

    <img
        class="crown__flats--background"
        src="./../assets/flats/flats-background-min.jpg"
        alt=""
    />
    <Menu/>
    <div
        v-if="sudost"
        :class="
        visible && sudost ? 'crown__flats--house-none' : 'crown__flats--house'
      "
    >
      <svg
          version="1.1"
          id="sudost"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 437 712"
          style="enable-background: new 0 0 437 712; transform: scale(-1, 1)"
          xml:space="preserve"
      >
        <g id="Слой_3">
          <image
              style="overflow: visible"
              width="437"
              height="712"
              href="./../assets/flats/_sud-ost.png"
          ></image>
        </g>

        <g id="fr1ft5" v-if="statusFlat('1.05')">
          <polygon
              :class="active_flat == '1.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.05');
            "
              points="276.7,596.3 276.7,570 366.5,577.4 366.5,599.3 	"
          />
          <title>1.05</title>
        </g>
        <g id="fr2ft5" v-if="statusFlat('2.05')">
          <polygon
              :class="active_flat == '2.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.05');
            "
              points="276.7,565 276.7,538.7 366.5,550.7 366.5,572.7 	"
          />
          <title>2.05</title>
        </g>
        <g id="fr3ft5" v-if="statusFlat('3.05')">
          <polygon
              :class="active_flat == '3.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.05');
            "
              points="276.7,533.6 276.7,507.2 366.5,523 366.5,544.9 	"
          />
          <title>3.05</title>
        </g>
        <g id="fr4ft5" v-if="statusFlat('4.05')">
          <polygon
              :class="active_flat == '4.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.05');
            "
              points="276.7,501.8 276.7,475.5 366.5,495 366.5,517 	"
          />
          <title>4.05</title>
        </g>
        <g id="fr5ft5" v-if="statusFlat('5.05')">
          <polygon
              :class="active_flat == '5.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.05');
            "
              points="276.7,470 276.7,443.6 366.5,468.7 366.5,490.7 	"
          />
          <title>5.05</title>
        </g>
        <g id="fr6ft5" v-if="statusFlat('6.05')">
          <polygon
              :class="active_flat == '6.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.05');
            "
              points="276.7,438.9 276.7,412.6 366.5,441 366.5,463 	"
          />
          <title>6.05</title>
        </g>
        <g id="fr7ft5" v-if="statusFlat('7.05')">
          <polygon
              :class="active_flat == '7.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.05');
            "
              points="276.7,407 276.7,380.7 366.5,413.7 366.5,435.7 	"
          />
          <title>7.05</title>
        </g>
        <g id="fr8ft5" v-if="statusFlat('8.05')">
          <polygon
              :class="active_flat == '8.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.05');
            "
              points="276.7,375.2 276.7,349.8 366.5,385.8 366.5,407.7 	"
          />
          <title>8.05</title>
        </g>
        <g id="fr9ft5" v-if="statusFlat('9.05')">
          <polygon
              :class="active_flat == '9.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.05');
            "
              points="276.7,343.9 276.7,317.5 366.5,358.4 366.5,380.3 	"
          />
          <title>9.05</title>
        </g>
        <g id="fr10ft5" v-if="statusFlat('10.05')">
          <polygon
              :class="active_flat == '10.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.05');
            "
              points="276.7,313.3 276.7,286.9 366.5,331.7 366.5,352.6 	"
          />
          <title>10.05</title>
        </g>
        <g id="fr11ft5" v-if="statusFlat('11.05')">
          <polygon
              :class="active_flat == '11.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.05');
            "
              points="276.7,281.1 276.7,254.8 366.5,303.2 366.5,325.1 	"
          />
          <title>11.05</title>
        </g>
        <g id="fr1ft4" v-if="statusFlat('1.04')">
          <polygon
              :class="active_flat == '1.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.04');
            "
              points="124.2,597.4 124.2,572.9 200.1,565.4 254.4,570 254.4,595.5 200.7,592.7 	"
          />
          <title>1.04</title>
        </g>
        <g id="fr2ft4" v-if="statusFlat('2.04')">
          <polygon
              :class="active_flat == '2.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.04');
            "
              points="124.2,567.9 124.2,542.4 200.1,531.4 254.4,537 254.4,562.5 200.7,557.7 	"
          />
          <title>2.04</title>
        </g>
        <g id="fr3ft4" v-if="statusFlat('3.04')">
          <polygon
              :class="active_flat == '3.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.04');
            "
              points="124.2,537.3 124.2,511.6 200.1,497.2 254.4,505.8 254.4,530.4 200.7,523.6 	"
          />
          <title>3.04</title>
        </g>
        <g id="fr4ft4" v-if="statusFlat('4.04')">
          <polygon
              :class="active_flat == '4.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.04');
            "
              points="124.2,506.8 124.2,481.3 200.1,462 254.4,472.6 254.4,497.1 200.7,488.3 	"
          />
          <title>4.04</title>
        </g>
        <g id="fr5ft4" v-if="statusFlat('5.04')">
          <polygon
              :class="active_flat == '5.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.04');
            "
              points="124.2,476.8 124.2,451.2 200.1,426.3 254.4,439.8 254.4,465.4 200.7,452.6 	"
          />
          <title>5.04</title>
        </g>
        <g id="fr6ft4" v-if="statusFlat('6.04')">
          <polygon
              :class="active_flat == '6.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.04');
            "
              points="124.2,446.5 124.2,420.9 200.1,391.5 254.4,408.1 254.4,432.7 200.7,418.9 	"
          />
          <title>6.04</title>
        </g>
        <g id="fr7ft4" v-if="statusFlat('7.04')">
          <polygon
              :class="active_flat == '7.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.04');
            "
              points="124.2,415.8 124.2,391.3 200.1,357 254.4,374.6 254.4,400.1 200.7,383.3 	"
          />
          <title>7.04</title>
        </g>
        <g id="fr8ft4" v-if="statusFlat('8.04')">
          <polygon
              :class="active_flat == '8.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.04');
            "
              points="124.2,386 124.2,361.5 200.1,321.4 254.4,343 254.4,367.5 200.7,348.7 	"
          />
          <title>8.04</title>
        </g>
        <g id="fr9ft4" v-if="statusFlat('9.04')">
          <polygon
              :class="active_flat == '9.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.04');
            "
              points="124.2,356.3 124.2,330.7 200.1,286.8 254.4,310.4 254.4,335 200.7,313.2 	"
          />
          <title>9.04</title>
        </g>
        <g id="fr10ft4" v-if="statusFlat('10.04')">
          <polygon
              :class="active_flat == '10.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.04');
            "
              points="124.2,326.6 124.2,301.1 200.1,251.3 254.4,276.9 254.4,302.4 200.7,279.6 	"
          />
          <title>10.04</title>
        </g>
        <g id="fr11ft4" v-if="statusFlat('11.04')">
          <polygon
              :class="active_flat == '11.04' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.04');
            "
              points="124.2,295.8 124.2,270.3 200.1,217.5 254.4,246.1 254.4,269.6 200.7,243.8 	"
          />
          <title>11.04</title>
        </g>
        <g id="fr1ft3" v-if="statusFlat('1.03')">
          <polygon
              :class="active_flat == '1.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.03');
            "
              points="119.2,597.4 91.5,599.2 91.5,576.6 119.2,573.8 	"
          />
          <title>1.03</title>
        </g>
        <g id="fr2ft3" v-if="statusFlat('2.03')">
          <polygon
              :class="active_flat == '2.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.03');
            "
              points="119.2,568.6 91.5,571.5 91.5,548 119.2,543.8 	"
          />
          <title>2.03</title>
        </g>
        <g id="fr3ft3" v-if="statusFlat('3.03')">
          <polygon
              :class="active_flat == '3.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.03');
            "
              points="119.2,538.4 91.5,543.7 91.5,519.2 119.2,512.5 	"
          />
          <title>3.03</title>
        </g>
        <g id="fr4ft3" v-if="statusFlat('4.03')">
          <polygon
              :class="active_flat == '4.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.03');
            "
              points="119.2,508.3 91.5,515.4 91.5,491.9 119.2,483.5 	"
          />
          <title>4.03</title>
        </g>
        <g id="fr5ft3" v-if="statusFlat('5.03')">
          <polygon
              :class="active_flat == '5.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.03');
            "
              points="119.2,478.3 91.5,486.5 91.5,464 119.2,453.5 	"
          />
          <title>5.03</title>
        </g>
        <g id="fr6ft3" v-if="statusFlat('6.03')">
          <polygon
              :class="active_flat == '6.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.03');
            "
              points="119.2,447.7 91.5,458.1 91.5,434.6 119.2,422.9 	"
          />
          <title>6.03</title>
        </g>
        <g id="fr7ft3" v-if="statusFlat('7.03')">
          <polygon
              :class="active_flat == '7.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.03');
            "
              points="119.2,418.6 91.5,429.7 91.5,406.2 119.2,393.7 	"
          />
          <title>7.03</title>
        </g>
        <g id="fr8ft3" v-if="statusFlat('8.03')">
          <polygon
              :class="active_flat == '8.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.03');
            "
              points="119.2,389.1 91.5,402 91.5,378.5 119.2,364.3 	"
          />
          <title>8.03</title>
        </g>
        <g id="fr9ft3" v-if="statusFlat('9.03')">
          <polygon
              :class="active_flat == '9.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.03');
            "
              points="119.2,359.2 91.5,373.8 91.5,350.3 119.2,334.3 	"
          />
          <title>9.03</title>
        </g>
        <g id="fr10ft3" v-if="statusFlat('10.03')">
          <polygon
              :class="active_flat == '10.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.03');
            "
              points="119.2,328.9 91.5,346 91.5,322.5 119.2,304 	"
          />
          <title>10.03</title>
        </g>
        <g id="fr11ft3" v-if="statusFlat('11.03')">
          <polygon
              :class="active_flat == '11.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.03');
            "
              points="119.2,298.3 91.5,317 91.5,293.5 119.2,273.5 	"
          />
          <title>11.03</title>
        </g>
        <g id="fr1ft2" v-if="statusFlat('1.02')">
          <polygon
              :class="active_flat == '1.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.02');
            "
              points="85.1,599.9 56.5,601.1 56.5,579.4 85.1,576.6 	"
          />
          <title>1.02</title>
        </g>
        <g id="fr2ft2" v-if="statusFlat('2.02')">
          <polygon
              :class="active_flat == '2.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.02');
            "
              points="85.1,572.7 56.5,575.9 56.5,554.2 85.1,549.4 	"
          />
          <title>2.02</title>
        </g>
        <g id="fr3ft2" v-if="statusFlat('3.02')">
          <polygon
              :class="active_flat == '3.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.02');
            "
              points="85.1,544.1 56.5,549.7 56.5,528 85.1,520.8 	"
          />
          <title>3.02</title>
        </g>
        <g id="fr4ft2" v-if="statusFlat('4.02')">
          <polygon
              :class="active_flat == '4.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.02');
            "
              points="85.1,516.4 56.5,523.3 56.5,501.6 85.1,493.1 	"
          />
          <title>4.02</title>
        </g>
        <g id="fr5ft2" v-if="statusFlat('5.02')">
          <polygon
              :class="active_flat == '5.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.02');
            "
              points="85.1,488.3 56.5,497.2 56.5,475.6 85.1,465.9 	"
          />
          <title>5.02</title>
        </g>
        <g id="fr6ft2" v-if="statusFlat('6.02')">
          <polygon
              :class="active_flat == '6.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.02');
            "
              points="85.1,461.5 56.5,470.9 56.5,451.3 85.1,438.3 	"
          />
          <title>6.02</title>
        </g>
        <g id="fr7ft2" v-if="statusFlat('7.02')">
          <polygon
              :class="active_flat == '7.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.02');
            "
              points="85.1,431.9 56.5,444.6 56.5,424.9 85.1,409.5 	"
          />
          <title>7.02</title>
        </g>
        <g id="fr8ft2" v-if="statusFlat('8.02')">
          <polygon
              :class="active_flat == '8.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.02');
            "
              points="85.1,404.6 56.5,419.6 56.5,397.9 85.1,381.3 	"
          />
          <title>8.02</title>
        </g>
        <g id="fr9ft2" v-if="statusFlat('9.02')">
          <polygon
              :class="active_flat == '9.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.02');
            "
              points="85.1,377 56.5,392.9 56.5,372.2 85.1,354.7 	"
          />
          <title>9.02</title>
        </g>
        <g id="fr10ft2" v-if="statusFlat('10.02')">
          <polygon
              :class="active_flat == '10.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.02');
            "
              points="85.1,349.4 56.5,367.2 56.5,345.5 85.1,326.1 	"
          />
          <title>10.02</title>
        </g>
        <g id="fr11ft2" v-if="statusFlat('11.02')">
          <polygon
              :class="active_flat == '11.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.02');
            "
              points="85.1,321.5 56.5,341 56.5,318.3 85.1,298.3 	"
          />
          <title>11.02</title>
        </g>
        <g id="fr14ft1" v-if="statusFlat('14.01')">
          <polygon
              :class="active_flat == '14.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('14.01');
            "
              points="103,222.8 200,141.3 366.5,242.7 366.5,221.3 200,113 103,200.1 	"
          />
          <title>14.01</title>
        </g>
        <g id="fr14ft2" v-if="statusFlat('14.02')">
          <polygon
              :class="active_flat == '14.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('14.02');
            "
              points="99.7,225.3 56.5,261.3 56.5,240.8 99.7,202.8 	"
          />
          <title>14.02</title>
        </g>
        <g id="fr13ft1" v-if="statusFlat('13.01')">
          <polygon
              :class="active_flat == '13.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('13.01');
            "
              points="103,251.7 200,174.2 366.5,271.6 366.5,249.3 200,146.9 103,229 		"
          />
          <title>13.01</title>
        </g>
        <g id="fr13ft2" v-if="statusFlat('13.02')">
          <polygon
              :class="active_flat == '13.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('13.02');
            "
              points="99.7,254.3 56.5,289.3 56.5,268.8 99.7,231.8 	"
          />
          <title>13.02</title>
        </g>
        <g id="fr12ft1" v-if="statusFlat('12.01')">
          <polygon
              :class="active_flat == '12.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('12.01');
            "
              points="99.7,282.5 56.5,314.5 56.5,294 99.7,260 	"
          />
          <title>12.01</title>
        </g>
        <g id="fr12ft2" v-if="statusFlat('12.02')">
          <polygon
              :class="active_flat == '12.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('12.02');
            "
              points="103.6,280.1 200.6,209.6 254.4,238.3 254.4,211 200.6,180.3 103.6,257.4 		"
          />
          <title>12.02</title>
        </g>
        <g id="fr12ft3" v-if="statusFlat('12.03')">
          <polygon
              :class="active_flat == '12.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('12.03');
            "
              points="276.7,249 276.7,224 366.5,276.9 366.5,298 	"
          />
          <title>12.03</title>
        </g>

        <g id="fr15ft1" v-if="statusFlat('15.01')">
          <polygon
              :class="active_flat == '15.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('15.01');
            "
              points="123.7,124 152.6,127.3 152.6,91.3 157.7,81.7 187.7,86.7 194,76.3 254.4,89 274.7,93.7 274.7,125 333.8,138.3
		333.8,156.3 344.7,158.7 347,201 201.3,104.2 123.7,175.3 	"
          >
            <title>15.01 - 16.01</title>
          </polygon>
        </g>
        <g id="fr15ft2" v-if="statusFlat('15.02')">
          <polygon
              :class="active_flat == '15.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('15.02');
            "
              points="84.2,210.7 115.3,182.4 115.3,156 84.2,156 	"
          >
            <title>15.02 - 16.02</title>
          </polygon>
        </g>
      </svg>

      <div
          v-if="flatModal"
          class="modal__back fixed-overlay__modal fixed-overlay__modal::after"
      >
        <div class="modal">
          <div class="modal_container">
            <div class="modal_container--close">
              <img
                  v-on:click="
                  flatModal = false;
                  active_flat = false;
                "
                  src="./../assets/close.png"
                  alt=""
              />
            </div>
            <p>
              <span
              >Wohnung
                <span v-if="name != '15.01' && name != '15.02'">{{
                    name
                  }}</span>

                <span v-if="name == '15.01'">{{ name }} - 16.01</span>

                <span v-if="name == '15.02'">{{ name }} - 16.02</span>
              </span>
            </p>
            <p>{{ floor[0] }}.Etage</p>
            <p v-if="RoomCount != 0">{{ RoomCount }} Zimmer</p>
            <p v-if="RoomCount == 0">Zimmer Keine Information</p>
            <!--<p>Ausblick Nord-West</p>-->
            <p v-if="Square != 0">Gesamtwohnfläche {{ Square }} m²</p>
            <p v-if="Square == 0">Gesamtwohnfläche Keine Information</p>
            <p>Noch verfügbar!</p>
            <button
                class="modal_container--button outline-none"
                v-on:click="clickFlat(key, name, key)"
            >
              Jetzt Kontakt aufnehmen
            </button
            >
            <br/>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="!sudost"
        :class="
        visible && !sudost ? 'crown__flats--house-none' : 'crown__flats--house'
      "
    >
      <svg
          version="1.1"
          id="nord-west"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 437 712"
          style="enable-background: new 0 0 437 712"
          xml:space="preserve"
      >
        <g id="Слой_3">
          <image
              style="overflow: visible"
              width="437"
              height="712"
              href="./../assets/flats/nord-west.png"
          ></image>
        </g>

        <g id="fr1ft1" v-if="statusFlat('1.01')">
          <polygon
              :class="active_flat == '1.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.01');
            "
              points="177.9,620.7 177.9,591.9 270,591.6 307.2,591.8 307.2,621.4 270.1,623.1 	"
          />
          <title>1.01</title>
        </g>
        <g id="fr2ft1" v-if="statusFlat('2.01')">
          <polygon
              :class="active_flat == '2.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.01');
            "
              points="177.9,587.4 177.9,557.6 270,556.3 307.2,557.5 307.2,587.1 270.1,586.8 	"
          />
          <title>2.01</title>
        </g>
        <g id="fr3ft1" v-if="statusFlat('3.01')">
          <polygon
              :class="active_flat == '3.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.01');
            "
              points="177.9,553.4 177.9,523.6 270,519.3 307.2,522.4 307.2,552.1 270.1,550.8 	"
          />
          <title>3.01</title>
        </g>
        <g id="fr4ft1" v-if="statusFlat('4.01')">
          <polygon
              :class="active_flat == '4.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.01');
            "
              points="177.9,518.4 177.9,488.6 270,483.3 307.2,488.4 307.2,517.1 270.1,513.8 	"
          />
          <title>4.01</title>
        </g>
        <g id="fr5ft1" v-if="statusFlat('5.01')">
          <polygon
              :class="active_flat == '5.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.01');
            "
              points="177.9,483.7 177.9,454.9 270,446.6 307.2,453.8 307.2,482.4 270.1,477.1 	"
          />
          <title>5.01</title>
        </g>
        <g id="fr6ft1" v-if="statusFlat('6.01')">
          <polygon
              :class="active_flat == '6.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.01');
            "
              points="177.9,449.4 177.9,419.6 270,410.3 307.2,419.4 307.2,448.1 270.1,440.8 	"
          />
          <title>6.01</title>
        </g>
        <g id="fr7ft1" v-if="statusFlat('7.01')">
          <polygon
              :class="active_flat == '7.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.01');
            "
              points="177.9,414.7 177.9,384.9 270,373.7 307.2,384.8 307.2,413.5 270.1,404.2 	"
          />
          <title>7.01</title>
        </g>
        <g id="fr8ft1" v-if="statusFlat('8.01')">
          <polygon
              :class="active_flat == '8.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.01');
            "
              points="177.9,379.5 177.9,350.7 270,337.5 307.2,350.6 307.2,378.3 270.1,367 	"
          />
          <title>8.01</title>
        </g>
        <g id="fr9ft1" v-if="statusFlat('9.01')">
          <polygon
              :class="active_flat == '9.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.01');
            "
              points="177.9,345.9 177.9,316.1 270,300.8 307.2,315.9 307.2,344.6 270.1,330.3 	"
          />
          <title>9.01</title>
        </g>
        <g id="fr10ft1" v-if="statusFlat('10.01')">
          <polygon
              :class="active_flat == '10.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.01');
            "
              points="177.9,312 177.9,282.2 270,264 307.2,281.1 307.2,309.8 270.1,294.5 	"
          />
          <title>10.01</title>
        </g>
        <g id="fr11ft1" v-if="statusFlat('11.01')">
          <polygon
              :class="active_flat == '11.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.01');
            "
              points="177.9,278.4 177.9,248.6 270,227.3 307.2,247.4 307.2,276.1 270.1,258.8 	"
          />
          <title>11.01</title>
        </g>
        <g id="fr12ft1" v-if="statusFlat('12.01')">
          <polygon
              :class="active_flat == '12.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('12.01');
            "
              points="21.3,277.9 21.3,252.1 270,191.3 307.2,211.4 307.2,240.1 270.1,221.8 	"
          />
          <title>12.01</title>
        </g>
        <g id="fr13ft2" v-if="statusFlat('13.02')">
          <polygon
              :class="active_flat == '13.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('13.02');
            "
              points="21.3,246.2 21.3,220.6 270,155.3 347,203.6 347,230.2 270.1,185.8 	"
          />
          <title>13.02</title>
        </g>
        <g id="fr14ft2" v-if="statusFlat('14.02')">
          <polygon
              :class="active_flat == '14.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('14.02');
            "
              points="21.3,215.4 21.3,189.6 270,118.8 348.3,172 348.3,197.5 270.1,149.3 	"
          />
          <title>14.02</title>
        </g>
        <g id="fr11ft2" v-if="statusFlat('11.02')">
          <polygon
              :class="active_flat == '11.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.02');
            "
              points="173.8,249.5 173.8,278.6 21.3,308.3 21.3,282.6 	"
          />
          <title>11.02</title>
        </g>
        <g id="fr10ft2" v-if="statusFlat('10.02')">
          <polygon
              :class="active_flat == '10.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.02');
            "
              points="173.8,283.1 173.8,312.2 21.3,338.9 21.3,313.2 	"
          />
          <title>10.02</title>
        </g>
        <g id="fr9ft2" v-if="statusFlat('9.02')">
          <polygon
              :class="active_flat == '9.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.02');
            "
              points="173.8,317.4 173.8,346.6 21.3,369.3 21.3,343.6 	"
          />
          <title>9.02</title>
        </g>
        <g id="fr8ft2" v-if="statusFlat('8.02')">
          <polygon
              :class="active_flat == '8.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.02');
            "
              points="173.8,351.8 173.8,380 21.3,400.7 21.3,374 	"
          />
          <title>8.02</title>
        </g>
        <g id="fr7ft2" v-if="statusFlat('7.02')">
          <polygon
              :class="active_flat == '7.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.02');
            "
              points="173.8,385.9 173.8,415.1 21.3,431.8 21.3,406.1 	"
          />
          <title>7.02</title>
        </g>
        <g id="fr6ft2" v-if="statusFlat('6.02')">
          <polygon
              :class="active_flat == '6.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.02');
            "
              points="173.8,420.5 173.8,449.6 21.3,462.3 21.3,436.6 	"
          />
          <title>6.02</title>
        </g>
        <g id="fr5ft2" v-if="statusFlat('5.02')">
          <polygon
              :class="active_flat == '5.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.02');
            "
              points="173.8,455.7 173.8,483.8 21.3,493.6 21.3,467.8 	"
          />
          <title>5.02</title>
        </g>
        <g id="fr4ft2" v-if="statusFlat('4.02')">
          <polygon
              :class="active_flat == '4.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.02');
            "
              points="173.8,489.4 173.8,518.5 21.3,524.2 21.3,498.5 	"
          />
          <title>4.02</title>
        </g>
        <g id="fr3ft2" v-if="statusFlat('3.02')">
          <polygon
              :class="active_flat == '3.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.02');
            "
              points="173.8,523.5 173.8,552.6 21.3,555.3 21.3,529.6 	"
          />
          <title>3.02</title>
        </g>
        <g id="fr2ft2" v-if="statusFlat('2.02')">
          <polygon
              :class="active_flat == '2.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.02');
            "
              points="173.8,557.9 173.8,587.1 21.3,587.3 21.3,560.6 	"
          />
          <title>2.02</title>
        </g>
        <g id="fr1ft2" v-if="statusFlat('1.02')">
          <polygon
              :class="active_flat == '1.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.02');
            "
              points="173.8,592.2 173.8,620.3 21.3,617.9 21.3,592.2 	"
          />
          <title>1.02</title>
        </g>
        <g id="fr12ft3" v-if="statusFlat('12.03')">
          <polygon
              :class="active_flat == '12.03' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('12.03');
            "
              points="315.7,216.9 387.9,257.9 387.9,284.6 315.7,245.3 	"
          />
          <title>12.03</title>
        </g>
        <g id="fr11ft6" v-if="statusFlat('11.06')">
          <polygon
              :class="active_flat == '11.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.06');
            "
              points="315.7,251 348.3,267.9 348.3,294.6 315.7,279.5 	"
          />
          <title>11.06</title>
        </g>
        <g id="fr10ft6" v-if="statusFlat('10.06')">
          <polygon
              :class="active_flat == '10.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.06');
            "
              points="315.7,285.5 348.3,300.4 348.3,327 315.7,313 	"
          />
          <title>10.06</title>
        </g>
        <g id="fr9ft6" v-if="statusFlat('9.06')">
          <polygon
              :class="active_flat == '9.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.06');
            "
              points="315.7,319.7 348.3,333.6 348.3,359.3 315.7,347.2 	"
          />
          <title>9.06</title>
        </g>
        <g id="fr8ft6" v-if="statusFlat('8.06')">
          <polygon
              :class="active_flat == '8.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.06');
            "
              points="315.7,354.1 348.3,366 348.3,392.7 315.7,381.6 	"
          />
          <title>8.06</title>
        </g>
        <g id="fr7ft6" v-if="statusFlat('7.06')">
          <polygon
              :class="active_flat == '7.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.06');
            "
              points="315.7,387.5 348.3,398.4 348.3,425.1 315.7,416 	"
          />
          <title>7.06</title>
        </g>
        <g id="fr6ft6" v-if="statusFlat('6.06')">
          <polygon
              :class="active_flat == '6.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.06');
            "
              points="315.7,421.8 348.3,429.9 348.3,456.5 315.7,450.3 	"
          />
          <title>6.06</title>
        </g>
        <g id="fr5ft6" v-if="statusFlat('5.06')">
          <polygon
              :class="active_flat == '5.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.06');
            "
              points="315.7,456.3 348.3,462.2 348.3,488.9 315.7,484.8 	"
          />
          <title>5.06</title>
        </g>
        <g id="fr4ft6" v-if="statusFlat('4.06')">
          <polygon
              :class="active_flat == '4.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.06');
            "
              points="315.7,489.8 348.3,494.7 348.3,521.4 315.7,518.3 	"
          />
          <title>4.06</title>
        </g>
        <g id="fr3ft6" v-if="statusFlat('3.06')">
          <polygon
              :class="active_flat == '3.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.06');
            "
              points="315.7,524.3 348.3,527.2 348.3,553.9 315.7,552.8 	"
          />
          <title>3.06</title>
        </g>
        <g id="fr2ft6" v-if="statusFlat('2.06')">
          <polygon
              :class="active_flat == '2.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.06');
            "
              points="315.7,558 348.3,558.8 348.3,586.5 315.7,586.5 	"
          />
          <title>2.06</title>
        </g>
        <g id="fr1ft6" v-if="statusFlat('1.06')">
          <polygon
              :class="active_flat == '1.06' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.06');
            "
              points="315.7,591.6 348.3,592.5 348.3,619.1 315.7,620.1 	"
          />
          <title>1.06</title>
        </g>
        <g id="fr11ft5" v-if="statusFlat('11.05')">
          <polygon
              :class="active_flat == '11.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('11.05');
            "
              points="355.4,271.3 387.9,288.1 387.9,312.8 355.4,297.8 	"
          />
          <title>11.05</title>
        </g>
        <g id="fr10ft5" v-if="statusFlat('10.05')">
          <polygon
              :class="active_flat == '10.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('10.05');
            "
              points="355.4,303.6 387.9,318.5 387.9,344.2 355.4,330.1 	"
          />
          <title>10.05</title>
        </g>
        <g id="fr9ft5" v-if="statusFlat('9.05')">
          <polygon
              :class="active_flat == '9.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('9.05');
            "
              points="355.4,335.7 387.9,349.6 387.9,374.3 355.4,362.2 	"
          />
          <title>9.05</title>
        </g>
        <g id="fr8ft5" v-if="statusFlat('8.05')">
          <polygon
              :class="active_flat == '8.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('8.05');
            "
              points="355.4,368.8 387.9,380.6 387.9,404.3 355.4,394.3 	"
          />
          <title>8.05</title>
        </g>
        <g id="fr7ft5" v-if="statusFlat('7.05')">
          <polygon
              :class="active_flat == '7.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('7.05');
            "
              points="355.4,400.8 387.9,410.6 387.9,434.3 355.4,426.3 	"
          />
          <title>7.05</title>
        </g>
        <g id="fr6ft5" v-if="statusFlat('6.05')">
          <polygon
              :class="active_flat == '6.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('6.05');
            "
              points="355.4,432.5 387.9,440.5 387.9,464.2 355.4,458 	"
          />
          <title>6.05</title>
        </g>
        <g id="fr5ft5" v-if="statusFlat('5.05')">
          <polygon
              :class="active_flat == '5.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('5.05');
            "
              points="355.4,464.2 387.9,471 387.9,494.6 355.4,489.6 	"
          />
          <title>5.05</title>
        </g>
        <g id="fr4ft5" v-if="statusFlat('4.05')">
          <polygon
              :class="active_flat == '4.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('4.05');
            "
              points="355.4,495.6 387.9,500.5 387.9,525.2 355.4,522.1 	"
          />
          <title>4.05</title>
        </g>
        <g id="fr3ft5" v-if="statusFlat('3.05')">
          <polygon
              :class="active_flat == '3.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('3.05');
            "
              points="355.4,527.6 387.9,530.5 387.9,555.2 355.4,554.1 	"
          />
          <title>3.05</title>
        </g>
        <g id="fr2ft5" v-if="statusFlat('2.05')">
          <polygon
              :class="active_flat == '2.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('2.05');
            "
              points="355.4,560 387.9,560.8 387.9,586.5 355.4,586.5 	"
          />
          <title>2.05</title>
        </g>
        <g id="fr1ft5" v-if="statusFlat('1.05')">
          <polygon
              :class="active_flat == '1.05' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('1.05');
            "
              points="355.4,592.6 387.9,592.4 387.9,618.1 355.4,619.1 	"
          />
          <title>1.05</title>
        </g>
        <g id="fr13ft1" v-if="statusFlat('13.01')">
          <polygon
              :class="active_flat == '13.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('13.01');
            "
              points="355.4,207.8 387.9,228.2 387.9,252.9 355.4,233.3 	"
          />
          <title>13.01</title>
        </g>
        <g id="fr14ft1" v-if="statusFlat('14.01')">
          <polygon
              :class="active_flat == '14.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('14.01');
            "
              points="355.4,176 387.9,198.7 387.9,221.4 355.4,201.5 	"
          />
          <title>14.01</title>
        </g>

        <g id="fr15ft02" v-if="statusFlat('15.02')">
          <polygon
              :class="active_flat == '15.02' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('15.02');
            "
              points="51.2,174.8 51.2,118.8 93,110.7 93,101.1 173.8,86.7 173.8,74.9 276.1,57.5 276.1,83.2 307.2,79.3 307.2,137.7
		269.6,110.7 	"
          >
            <title>15.02 - 16.02</title>
          </polygon>
        </g>
        <g id="fr15ft01" v-if="statusFlat('15.01')">
          <polygon
              :class="active_flat == '15.01' ? 'st0 active-flat' : 'st0'"
              v-on:click="
              flatModal = !flatModal;
              flatHouse('15.01');
            "
              points="315.7,97.2 331.3,94.1 331.3,110.2 348.3,107.6 348.3,164.7 315.7,142.9 	"
          >
            <title>15.01 - 16.01</title>
          </polygon>
        </g>
      </svg>

      <div
          v-if="flatModal"
          class="modal__back fixed-overlay__modal fixed-overlay__modal::after"
      >
        <div class="modal">
          <div class="modal_container">
            <div class="modal_container--close">
              <img
                  v-on:click="
                  flatModal = false;
                  active_flat = false;
                "
                  src="./../assets/close.png"
                  alt=""
              />
            </div>
            <p>
              <span
              >Wohnung
                <span v-if="name != '15.01' && name != '15.02'">{{
                    name
                  }}</span>

                <span v-if="name == '15.01'">{{ name }} - 16.01</span>

                <span v-if="name == '15.02'">{{ name }} - 16.02</span>
              </span>
            </p>
            <p>{{ floor[0] }}.Etage</p>
            <p v-if="RoomCount != 0">{{ RoomCount }} Zimmer</p>
            <p v-if="RoomCount == 0">Zimmer Keine Information</p>
            <!--<p>Ausblick Nord-West</p>-->
            <p v-if="Square != 0">Gesamtwohnfläche {{ Square }} m²</p>
            <p v-if="Square == 0">Gesamtwohnfläche Keine Information</p>
            <p>Noch verfügbar!</p>
            <button
                class="modal_container--button"
                v-on:click="clickFlat(key, name, key)"
            >
              Jetzt Kontakt aufnehmen
            </button
            >
            <br/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="visible" class="crown__flats--flats-table">
      <div class="table__head">
        <div class="table__head--name">Wohnungsfinder</div>
        <div
            v-on:click="visible = !visible"
            class="table__head--button pointer"
        >
          Wohnungsliste &#9660;
        </div>
      </div>
      <section>
        <header class="header-padding">
          <div class="col">Wohnung</div>
          <div class="col">Etage</div>
          <div class="col">Zimmer</div>
          <div class="col">Größe</div>
          <div class="col">Ausblick</div>
<!--          <div class="col">Grundriss</div>-->
          <div class="col">Kaufpreis</div>
          <div class="col">Personalisieren</div>
        </header>
        <div class="row-padding">
          <div

              v-for="(flat, index) in arr_flats_name"
              :key="index"
          >
            <div

                v-if="flat.status === 0"
                 :class="
              flat.name == '15.01' || flat.name == '15.02'
                ? 'active-line-none'
                : ''"
                 class="row active-line"
            >
            <div class="col">
              {{ flat.name }}
            </div>
            <div class="col">{{ flat.floor.toFixed(0) }} OG</div>
            <div class="col">
              <span v-if="flat.RoomCount != 0">
                {{ flat.RoomCount }}
              </span>
              <span v-if="flat.RoomCount == 0"> - </span>
            </div>
            <div class="col">
              <span v-if="flat.Square != 0">
                {{ changeSquare(flat.Square) }} m²
              </span>
              <span v-if="flat.Square == 0"> - </span>
            </div>

            <div class="col">{{ flat.side }}</div>

<!--            <div class="col">-->
<!--              <span-->
<!--                  class="pointer"-->
<!--                  v-if="flat.status == 0"-->
<!--                  v-on:click="clickFlatGrundriss()"-->
<!--              >-->
<!--                Grundriss-->
<!--              </span>-->
<!--              <span v-if="flat.status == 1"> - </span>-->
<!--            </div>-->
            <div class="col">
              <span v-if="flat.status == 0">
                {{ editSum(Number(flat.cost)) }}
              </span>
              <span v-if="flat.status == 1"> &ensp; </span>
            </div>
            <div class="col">
              <span
                  class="pointer"
                  v-if="flat.status == 0"
                  v-on:click="clickFlat(flat.key, flat.name, flat.key)"
              >
                Verfügbar
              </span>
              <span v-if="flat.status == 1"> Verkauft </span>
              <span v-if="flat.status == 2"> Reserviert </span>
            </div>
          </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="!visible" class="crown__flats--flats-table">
      <div class="none-padding"></div>
      <div class="table__head">
        <div class="table__head--name">Wohnungsfinder</div>
        <div class="crown__house--side-homeside">
          <button
              v-on:click="sudost = true"
              class="side-button pointer-side"
              :class="sudost ? 'side-button-active' : ''"
          >
            Ansicht: Süd-Ost
          </button>
          &nbsp; | &nbsp;
          <button
              v-on:click="sudost = false"
              class="side-button pointer-side"
              :class="!sudost ? 'side-button-active' : ''"
          >
            Ansicht: Nord-West
          </button>
        </div>
        <div
            v-on:click="visible = !visible"
            class="table__head--button pointer"
        >
          Wohnungsliste &#9650;
        </div>
      </div>
    </div>
    <div class="crown__flats--footer">
      <img
          v-if="sudost"
          class="shadow-so"
          src="./../assets/flats/shadow.png"
          alt=""
      />
      <img v-if="!sudost" src="./../assets/flats/shadow-nord.png" alt=""/>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import axios from 'axios'
import moment from 'moment'
import {mapGetters} from "vuex";

export default {
  data: function () {
    return {
      startLoader: true,
      disclaimer: false,
      house: {},
      floor: '',
      floors: {},
      flats: {},
      visible: false,
      flatModal: false,
      sudost: true,
      active_flat: '',
      rooms: {},
      arr: [],
      arr_flats: [],
      temp: {},
      bd: {},
      arrFloors: {},
      arr_flats_name: [],
      name: '',
      key: '',
      RoomCount: '',
      Square: '',
      sideImg: {}
    };
  },
  props: {},
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  components: {
    Menu
  },
  methods: {
    changeSquare(square) {
      let p = square.toFixed(2)
      let str = new String(p);
      //parseFloat(str).toFixed(2);
      str = str
          .replace(/\./g, ',')

      if (Number.isInteger(square) == true)
        return square + ',00'
      else
        return str
    },
    editSum(sum) {
      var value = (Number(sum)).toLocaleString("en-US", {
        style: "currency",
        currency: "EUR",
      })
      var str = new String(value);
      str = str
          .replace(/,/g, "__COMMA__") // Replace `,` by some unique string
          .replace(/\./g, ',')         // Replace `.` by `,`
          .replace(/__COMMA__/g, '.'); // Replace the string by `.`
      str = str.replace(/€/g, '');
      str = str + ' €'
      return str
    },
    clickFlat() {
      this.$router.push({ name: 'Kontakt' });
    },
    clickFlatGrundriss() {
      this.$router.push({ name: 'Kontakt' });
    },

    flatHouse(flat) {
      this.active_flat = flat
      this.floor = 'none'
      this.name = 'none'
      this.key = 'none'

      this.RoomCount = 'none'
      this.Square = 'none'


      for (let i = 0; i < this.arr_flats_name.length; i++) {
        if (this.arr_flats_name[i].name == flat) {
          this.name = this.arr_flats_name[i].name;
          this.key = this.arr_flats_name[i].key;

          this.RoomCount = this.arr_flats_name[i].RoomCount
          this.Square = this.arr_flats_name[i].Square
        }
      }
      this.floor = String(this.name).split(".");
    },

    sortedUsers(arr) {
      return arr.sort((a, b) => a.name > b.name ? 1 : -1);
    },
    statusFlat(flat) {
      if (this.user.loggedIn == true)
        if (this.user.data.displayName == 'Superadmin')
          return true

      if (this.user.loggedIn == false) {
        for (let key in this.arr_flats_name) {
          if (this.arr_flats_name[key].name == flat && this.arr_flats_name[key].status == 0)
            return true
          if (this.arr_flats_name[key].name == flat && this.arr_flats_name[key].status == 1)
            return false
        }
      }
    },

    sendAkzeptieren(action) {
      if (action) {
        if (this.$store.getters.user.loggedIn == false) {
          let url = 'https://us-central1-cadman-63dc7.cloudfunctions.net/setDisclimer?localStorage=' + localStorage.getItem('crown') + '&date=' + moment().unix()
          axios.get(url)
              .then((response) => {
                console.log(response)
              })
              .finally(() => {
                this.disclaimer = false
              })
        }
        if (this.$store.getters.user.loggedIn == true) {
          let urlUser = 'https://us-central1-cadman-63dc7.cloudfunctions.net/setAuthDisclimer?localStorage=' + localStorage.getItem('crown') + '&date=' + moment().unix()
          axios.get(urlUser)
              .then((response) => {
                console.log(response)
              })
              .finally(() => {
                this.disclaimer = false
              })
        }
      }
      if (!action) {
        this.$router.push('/').catch(() => {
        });
      }
    }
  },
  mounted() {

    axios.get('https://cadman-63dc7.firebaseio.com/ImageData/.json')
        .then((response) => {
          this.sideImg = response.data
        })

    this.visible = false
    let urlAnonim = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getDisclimer?localStorage=' + localStorage.getItem('crown')
    let urlUser = 'https://us-central1-cadman-63dc7.cloudfunctions.net/getAuthDisclimer?localStorage=' + localStorage.getItem('crown')
    var q = null
    this.disclaimer = false
    this.startLoader = true

    axios.get('https://cadman-63dc7.firebaseio.com/Houses/.json')
        .then((response) => {
          this.houses = response.data
        })
        .finally(() => {
          axios.get('https://cadman-63dc7.firebaseio.com/Floors.json')
              .then((response) => {
                this.floors = response.data
              })
              .finally(() => {
                axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
                    .then((response) => {
                      this.flats = response.data
                    })
                    .finally(() => {

                      this.startLoader = true

                      if (this.user.loggedIn == false) {
                        axios.get(urlAnonim)
                            .then((response) => {
                              q = response.data
                            })
                            .finally(() => {
                              if (q == true) {
                                this.disclaimer = false
                              }
                              if (q == false) {
                                this.disclaimer = true
                              }

                              for (var key in this.houses) {
                                if (key == 'f336c1a5-8cf2-4141-9509-51ab08eddfb0')
                                  this.arr = this.houses[key].Data.SubEntitiesIds
                              }
                              for (var key_floors in this.floors) {
                                for (let i = 0; i < this.arr.length; i++) {
                                  if (this.arr[i] == key_floors)
                                    for (let j = 0; j < this.floors[key_floors].Data.SubEntitiesIds.length; j++) {
                                      this.arr_flats.push(this.floors[key_floors].Data.SubEntitiesIds[j])
                                    }
                                }
                              }
                              for (let i = 0; i < this.arr_flats.length; i++) {
                                for (var key_flats in this.flats) {
                                  if (key_flats == this.arr_flats[i]) {

                                    let s

                                    for (let key in this.sideImg) {
                                      for (let i = 0; i < this.flats[key_flats].Data.SubEntitiesIds.length; i++) {
                                        if (key == this.flats[key_flats].Data.SubEntitiesIds[i] + '-NONE-0')
                                          s = this.sideImg[key].Name
                                      }
                                    }

                                    this.arr_flats_name.push({
                                      key: key_flats,
                                      name: Number(this.flats[key_flats].Data.displayName),
                                      status: this.flats[key_flats].Data.State,
                                      floor: Number(this.flats[key_flats].Data.displayName),
                                      RoomCount: this.flats[key_flats].Data.RoomCount,
                                      Square: this.flats[key_flats].Data.Square,
                                      cost: this.flats[key_flats].Data.UDID,
                                      side: s
                                    })
                                  }
                                }
                              }
                              this.startLoader = false
                              this.sortedUsers(this.arr_flats_name)

                              let el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 3
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);


                            })
                      }

                      if (this.user.loggedIn == true && this.user.data.displayName == 'Superadmin') {
                        axios.get(urlUser)
                            .then((response) => {
                              q = response.data
                            })
                            .finally(() => {
                              if (q == true) {
                                this.disclaimer = false
                              }
                              if (q == false) {
                                this.disclaimer = true
                              }

                              for (var key in this.houses) {
                                if (key == 'f336c1a5-8cf2-4141-9509-51ab08eddfb0')
                                  this.arr = this.houses[key].Data.SubEntitiesIds
                              }
                              for (var key_floors in this.floors) {
                                for (let i = 0; i < this.arr.length; i++) {
                                  if (this.arr[i] == key_floors)
                                    for (let j = 0; j < this.floors[key_floors].Data.SubEntitiesIds.length; j++) {
                                      this.arr_flats.push(this.floors[key_floors].Data.SubEntitiesIds[j])
                                    }
                                }
                              }
                              for (let i = 0; i < this.arr_flats.length; i++) {
                                for (var key_flats in this.flats) {
                                  if (key_flats == this.arr_flats[i]) {

                                    let s

                                    for (let key in this.sideImg) {
                                      for (let i = 0; i < this.flats[key_flats].Data.SubEntitiesIds.length; i++) {
                                        if (key == this.flats[key_flats].Data.SubEntitiesIds[i] + '-NONE-0')
                                          s = this.sideImg[key].Name
                                      }
                                    }

                                    this.arr_flats_name.push({
                                      key: key_flats,
                                      name: Number(this.flats[key_flats].Data.displayName),
                                      status: 0,
                                      floor: Number(this.flats[key_flats].Data.displayName),
                                      RoomCount: this.flats[key_flats].Data.RoomCount,
                                      Square: this.flats[key_flats].Data.Square,
                                      cost: this.flats[key_flats].Data.UDID,
                                      side: s
                                    })
                                  }
                                }
                              }
                              this.startLoader = false
                              this.sortedUsers(this.arr_flats_name)

                              let el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 3
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);

                              el = this.arr_flats_name.length - 4
                              this.arr_flats_name.push(this.arr_flats_name.splice(el, 1)[0]);


                            })
                      }


                    })
              })
        })

  }
}
</script>

<style scoped>
button {
  padding: 0;
  margin: 1em;
  border: 0px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 1px #323a41;
}

.crown__flats--background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: 100%;
}

.crown__flats {
  width: 100%;
  text-align: center;
}

.crown__flats--flats-menu {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.crown__flats--flats-menu > div {
  padding: 0.5em;
}

.crown__flats--footer {
  position: fixed;
  height: 25%;
  bottom: 0;
  width: 100vw;
  background: rgb(50, 58, 65);
  background: linear-gradient(
      180deg,
      rgba(50, 58, 65, 1) 0%,
      rgba(50, 58, 65, 0.3) 100%
  );
  z-index: 0;
}

.crown__flats--footer img {
  height: 65vh;
  top: -1em;
  position: relative;
}

.crown__flats--flats-table {
  position: fixed;
  width: 100%;
  max-height: 60vh;
  margin: 0 auto;
  font-family: "35-FTR", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  text-decoration: none;
  bottom: 35px;
  z-index: 1;
}

.crown__flats--flats-table section {
  position: relative;
  bottom: 0.05em;
  height: calc(100% - 28px);
  width: 1178px;
  margin: 0 auto;
  background-color: #eeedec;
  overflow-y: scroll;
}

.crown__flats--flats-table .table__head {
  position: relative;
  bottom: 0.05em;
  height: 35px;
  width: 1178px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.crown__flats--flats-table .table__head--name {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
  font-size: 15px;
}

.crown__flats--flats-table .table__head--button {
  padding-right: 2em;
}

.crown__flats--flats-table header {
  font-weight: bold;
}

.row-padding {
  padding-left: 2em;
  padding-right: 2em;
}

header,
.row {
  display: flex;
  padding: 1em;
  border-bottom: 1px solid #323a41;
  padding-left: 0;
  padding-right: 0;
}

header {
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #707070;
}

.header-padding {
  padding-left: 2em;
  padding-right: 2em;
}

.col {
  flex: 1;
}

.none-padding {
  height: calc(100% - 28px);
  width: 100px;
}

.crown__house--side-homeside button {
  margin: 0;
  text-shadow: 0px 0px;
  font-size: 15px;
}

a {
  color: black;
}

a:hover {
  background-color: black;
}

.crown__flats--house {
  position: relative;
  margin: 0 auto;
  z-index: 2;
}

.crown__flats--house-none {
  margin: 0 auto;
  z-index: 2;
}

.st0 {
  fill: #ffffff;
}

.st0 {
  fill: red !important;
  opacity: 0;
}

.st0:hover {
  fill: #ad8059 !important;
  opacity: 0.7;
}

.active-flat {
  fill: #ad8059 !important;
  opacity: 0.7;
}

.fixed-overlay__modal {
  text-align: center;
  white-space: nowrap;
}

.fixed-overlay__modal::after {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: "";
}

.modal {
  display: inline-block;
  vertical-align: middle;
}

.modal_container {
  position: relative;
  margin: 50px;
  padding: 20px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  color: #323a41;
  text-align: center;
  font-family: "35-FTR", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  text-decoration: none;
}

.modal_container span {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1em;
}

.modal_container--button {
  margin-bottom: 1em;
  color: #323a41;
  text-shadow: 0px 0px 0px #323a41;
  font-weight: bold;
  cursor: pointer;
}

.modal_container--close {
  position: absolute;
  padding: 1em;
  right: 0;
  top: 0;
}

svg#nord-west {
  position: relative;
  height: 65vh;
  margin: 0 auto;
}

svg#sudost {
  position: relative;
  height: 65vh;
  margin: 0 auto;
}

.modal__back {
  position: absolute;
  top: 0;
  left: -50%;
  right: 0;
  bottom: 0;
}

.active-line:hover {
  background-color: #d6cec1;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: #ad8059;
}

.pointer-side {
  cursor: pointer;
}

.fixed-overlay__modal-disclaimer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  line-height: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(10px);
  text-align: center;
  white-space: nowrap;
}

.fixed-overlay__modal-disclaimer::after {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: "";
  backdrop-filter: blur(10px);
  opacity: 0.5;
}

.modal-disclaimer {
  display: inline-block;
  vertical-align: middle;
}

.modal_container-disclaimer {
  margin: 6em;
  padding: 2em;
  padding-left: 4em;
  padding-right: 4em;
  min-width: 200px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  color: #323a41;
}

.modal_container-disclaimer h3 {
  font-size: 24px;
}

.modal_container-disclaimer button {
  border: none;
  font: inherit;
  color: inherit;
  background-color: #d6cec1;
  padding: 1em;
  margin: 0;
  margin-right: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  text-shadow: 0px 0px 0px #323a41;
}

.pointer {
  cursor: pointer;
}

.outline-none {
  outline: none;
}

.info {
  z-index: 999;
  position: fixed;
}

button.side-button {
  font-weight: 300;
}

button.side-button.side-button-active {
  font-weight: 600;
}

button.side-button:focus,
button.side-button:active {
  outline: none;
}

button.side-button::-moz-focus-inner {
  border: 0;
}

.col.price {
  text-align: right;
}

.active-line-none {
  border: 0;
}

.shadow-so {
  transform: scale(-1, 1);
}
</style>
